import { Nav, Hero, Footer, LeadPackages, PageContainer } from "../../components";

export const Home = () => {
  return (
    <PageContainer>
      <Nav />
      <Hero />
      <LeadPackages id="packages" />
      <Footer />
    </PageContainer>
  );
};
