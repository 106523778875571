import React, { useState } from "react";
import { SessionStorageKeys } from "../constants/session-storage";

import { PurchaseContext } from "./purchase-context";

export const PurchaseProvider = ({ children }) => {
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [quantity, setQuantity] = useState("20");
  const [leadLocationKey, setLeadLocationKey] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const setSessionStorageValue = (key, value) => {
    return sessionStorage.setItem(key, value);
  };

  const getSessionStorageValue = (key, value) => {
    return sessionStorage.getItem(key);
  };

  const clearSession = () => {
    sessionStorage.removeItem(SessionStorageKeys.LEAD_LOCATION_KEY);
    sessionStorage.removeItem(SessionStorageKeys.FIRST_NAME);
    sessionStorage.removeItem(SessionStorageKeys.LAST_NAME);
    sessionStorage.removeItem(SessionStorageKeys.EMAIL);
    sessionStorage.removeItem(SessionStorageKeys.QUANTITY);
    sessionStorage.removeItem(SessionStorageKeys.LEAD_TYPE);
  };

  const contextValues = {
    // Values
    stripeClientSecret,
    quantity,
    leadLocationKey,
    firstName,
    lastName,
    email,

    // GETTERS //
    getSessionStorageValue,

    // SETTERS //
    setStripeClientSecret,
    setLeadLocationKey,
    setFirstName,
    setLastName,
    setEmail,
    setQuantity,
    setSessionStorageValue,

    // Clears //
    clearSession,
  };

  return (
    <PurchaseContext.Provider value={contextValues}>
      {children}
    </PurchaseContext.Provider>
  );
};
