/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generateCampaign = /* GraphQL */ `
  mutation GenerateCampaign($input: GenerateCampaignInput!) {
    generateCampaign(input: $input) {
      statusCode
      campaignCreated
    }
  }
`;
