import { StyledButton, StyledButtonA } from "./styles";
import { ButtonTypes } from "../../constants/types";

export const Button = ({
  type,
  styleType = ButtonTypes.PRIMARY,
  children,
  href,
  className,
  onClick,
}) => {
  return type === "button" ? (
    <StyledButton
      className={className}
      href={href}
      styleType={styleType}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  ) : (
    <StyledButtonA
      className={className}
      href={href}
      styleType={styleType}
      onClick={onClick}
    >
      {children}
    </StyledButtonA>
  );
};
