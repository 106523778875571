import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { H1, H5 } from "../..";
import { Button } from "../../button/button";

import {
  StyledCard,
  StyledForm,
  StyledSpinner,
  StyledPaymentMessage,
  StyledLeft,
  StyledRight,
  StyledInput,
  StyledLabel,
  StyledFeilds,
  StyledFeild,
  StyledFooterWrapper,
} from "./styles";
import { PurchaseContext } from "../../../context/purchase-context";
import { SessionStorageKeys } from "../../../constants/session-storage";

export const CheckoutForm = ({ leadType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    firstName,
    lastName,
    email,
    leadLocationKey,
    quantity,
    setFirstName,
    setLastName,
    setEmail,
    setSessionStorageValue,
  } = useContext(PurchaseContext);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    setSessionStorageValue(
      SessionStorageKeys.LEAD_LOCATION_KEY,
      leadLocationKey
    );
    setSessionStorageValue(SessionStorageKeys.FIRST_NAME, firstName);
    setSessionStorageValue(SessionStorageKeys.LAST_NAME, lastName);
    setSessionStorageValue(SessionStorageKeys.EMAIL, email);
    setSessionStorageValue(SessionStorageKeys.QUANTITY, quantity);
    setSessionStorageValue(SessionStorageKeys.LEAD_TYPE, leadType);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: process.env.REACT_APP_STRIPE_SUCCESS_URL,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const handleSetFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleSetLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleSetEmail = (e) => {
    setEmail(e.target.value);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const buttonDisabled = useMemo(() => {
    return !(firstName && lastName && email);
  }, [firstName, lastName, email]);

  return (
    <StyledCard>
      <StyledLeft>
        <H1>Checkout</H1>
        <H5>Please fill out contact information below.</H5>
        <StyledFeilds>
          <StyledFeild>
            <StyledLabel>First Name</StyledLabel>
            <StyledInput value={firstName} onChange={handleSetFirstName} />
          </StyledFeild>
          <StyledFeild>
            <StyledLabel>Last Name</StyledLabel>
            <StyledInput value={lastName} onChange={handleSetLastName} />
          </StyledFeild>
          <StyledFeild>
            <StyledLabel>Email</StyledLabel>
            <StyledInput type="email" value={email} onChange={handleSetEmail} />
          </StyledFeild>
        </StyledFeilds>
      </StyledLeft>
      <StyledRight>
        <StyledForm id="payment-form">
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          {/* Show any error or success messages */}
          {message && <StyledPaymentMessage>{message}</StyledPaymentMessage>}
        </StyledForm>
        <StyledFooterWrapper>
          <Button
            disabled={isLoading || !stripe || !elements}
            className={buttonDisabled && "disabled"}
            id="submit"
            onClick={!buttonDisabled ? handleSubmit : () => {}}
          >
            <span id="button-text">
              {isLoading ? (
                <StyledSpinner id="spinner"></StyledSpinner>
              ) : (
                "Order Now"
              )}
            </span>
          </Button>
        </StyledFooterWrapper>
      </StyledRight>
    </StyledCard>
  );
};
