import { Card } from "../../";
import { theme } from "../../../theme";
import styled from "styled-components";
import { Checkmark } from "grommet-icons";

import backgroundImage from "../../../assets/images/dl_background_rotated.jpeg";

export const StyledCard = styled(Card)`
  width: 960px;
  min-height: 574px;
  padding: 0;

  @media (max-width: 960px) {
    padding: 0px 10px;
    flex-direction: column;
    align-items: center;
    width: auto;
  }
`;

export const StyledLeft = styled.div`
  display: flex;
  background-image: url(${backgroundImage});
  flex-direction: column;
  padding: 40px;
  width: 45%;
  @media (max-width: 960px) {
    width: 95%;
  }
`;

export const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: -2px 0 15px rgb(0 0 0 / 10%);
  flex-direction: column;
  padding: 40px;
  width: 55%;
  @media (max-width: 960px) {
    width: 95%;
  }
`;

export const StyledFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const StyledUpperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const StyledPrice = styled.span`
  font-size: 40px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  color: ${theme.primaryText.color};
  display: flex;
  align-items: flex-start;

  span {
    font-size: 20px;
    opacity: 0.8;
    margin-left: 5px;
    margin-top: 7px;
  }
`;

export const StyledDetails = styled.ul`
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  height: 100%;
`;

export const StyledDetail = styled.li`
  font-size: 12px;
  line-height: 18px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: ${theme.primaryText.color};
`;

export const StyledCheckmark = styled(Checkmark).attrs({
  size: "small",
})`
  margin-right: 5px;

  &&& path {
    stroke: ${theme.card.checkFillColor};
  }
`;

export const StyledHeaderWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledRow = styled.div`
  margin-bottom: 30px;
`;

export const StyledLeadQuanity = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledTotalWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${theme.purchaseForm.borderColor};
  margin-bottom: 10px;
  margin-top: 40px;

  div {
    padding-top: 15px;
  }
`;

export const StyledTotalLabel = styled.span`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  color: ${theme.primaryText.color};
  margin-top: 0;
  margin-right: 12px;
`;

export const StyledTotalValue = styled.span`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  color: ${theme.primaryText.color};
  margin-top: 0;
`;

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

export const StyledDisclaimer = styled.span`
  font-size: 8px;
  line-height: 14px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 300;
  display: block;
`;

export const StyledInputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
    line-height: 18px;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 400;
    margin-left: 20px;
  }
`;