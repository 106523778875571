import { Amplify } from "aws-amplify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import awsExports from "./aws-exports";
import {
  Home,
  PrivacyPolicy,
  Order,
  Success,
  Checkout,
  Contact,
  Terms,
} from "./pages";
import { PurchaseProvider } from "./context/purchase-provider";
import { Paths } from "./constants/paths";

const App = () => {
  // Set up and configure Amplify instance globally for the app.
  Amplify.configure(awsExports);

  return (
    <PurchaseProvider>
      <Router>
        <Routes>
          <Route path={Paths.HOME} element={<Home />} />
          <Route path={Paths.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={Paths.TERMS} element={<Terms />} />
          <Route path={Paths.ORDER} element={<Order />} />
          <Route path={Paths.CHECKOUT} element={<Checkout />} />
          <Route path={Paths.SUCCESS} element={<Success />} />
          <Route path={Paths.CONTACT} element={<Contact />} />
        </Routes>
      </Router>
    </PurchaseProvider>
  );
};

export default App;
