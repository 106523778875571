import { useEffect } from "react";
import { Nav, Footer } from "../../components";

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Nav />
      <div className="container">
        <main>
          <h1 className="card-header">
            Dependable Leads LLC Terms and Conditions/Purchase Agreement
          </h1>

          <p className="bold-big">
            The terms and conditions (“terms”) herein are a legal, binding
            agreement between Dependable Leads LLC and you, receiving the
            services from us or via our site (“Agent” or “you”). Your acceptance
            of the terms here is required for your use of the site and any of
            our services, and to our provision of leads to you. By clicking
            “Accept”, which represents your electronic signature on the terms,
            you accept these terms. You accept that your interaction with the
            website and each request you make for our services is subject to the
            terms here. If you disagree with any of the terms here, don’t use
            our services.
          </p>
          <p className="bold-big">Leads Purchase </p>
          <p>
            You agree to purchase leads in line with the guidelines here. A lead
            will contain information that has been provided by a person as well
            as certain lead contact information as available, unless otherwise
            agreed by you and us in writing, such as: (a) first and last name,
            (b) phone number, (c) email address, and (d) mailing address.
            Dependable Leads LLC doesn’t guarantee the quality, quantity or
            adequacy of the leads that are offered to you.{" "}
          </p>
          <p>
            When you place an order with us, you are 'opting in' to receive
            Emails with your lead's information. By placing an order, you agree
            to receive text message and emails from Dependable Leads LLC. You
            will receive a email per every lead generated. You may unsubscribe
            at any time by clicking the 'unsubscribe' link at the bottom of your
            emails.
          </p>
          <p>
            You’ll be charged for the leads based on the price stated at the
            time of the purchase, and fees may differ from purchase to purchase
            at any time. We do our absolute best to begin delivering leads
            between 24 – 72 hours, but because we are dependent on Facebook’s
            approval of our campaign, it may take up to 10 days to deliver an
            order. However, in some cases, orders can experience slight delays
            owing to daily supply and demand market conditions on the Social
            media platforms we advertise on. In such a rare case, you will be
            notified and your leads will be delivered in a timely manner.
          </p>
          <p>
            Purchases of leads are not cancellable, and there is no refund on
            fees paid. You’re not authorized to use any services that are not
            paid fully for.
          </p>
          <p className="bold-big">Authorized Use and Purpose of Leads</p>
          <p>
            Leads are rendered on a 100% Exclusive basis for a particular
            purpose for which the consumer submitted the online form (“the
            specific vertical”), for instance, life insurance. You’re allowed to
            use the leads provided by us exclusively for your internal marketing
            purposes in the specific vertical to the consumers. You may not use
            the lead for purposes other than those that the consumer has
            expressly inquired about, or of third parties. Agents can’t
            redistribute or sell our leads.
          </p>
          <p>
            Leads are confidential information of Dependable Leads LLC. You
            agree to not share with others, distribute, license, sell, resell or
            otherwise disclose any of the leads or the information included in
            them, to any third party for any purpose, unless only to an entity
            employed by you as a contractor for the main purpose of assisting
            you under the terms here as well as in accordance with the
            authorized use of the leads by you. When leads are generated, we
            don’t guarantee any certain results. A lead is a prospect, so don’t
            regard it as a guaranteed sale.
          </p>

          <p className="bold-big">Service Usage </p>

          <p>
            It is solely your responsibility to know about all laws, rules, and
            regulations applicable to the access, use, storage and security of
            data identifying consumers that we offer to you, and to act in line
            with them. You agree that you’ll maintain commercially reasonable
            physical, electronic and procedural controls, care and protection to
            protect any such consumer information received under the terms
            herein from any unauthorized use or disclosure, including the
            maintenance of proper security to limit access to the consumer data
            solely to carry out the purposes for which such information was
            disclosed.
          </p>

          <p className="bold-big">Warranties & Disclaimer</p>
          <p className="bold-big">
            As an Agent, you warrant and represent that:
          </p>
          <p>
            -the use of the leads and any interactions with and information
            provided to consumers will be in compliance with all applicable
            federal, state and local laws, statutes, rules and regulations,
            governing the marketing, sales, and/or promotion of goods and/or
            services, including with no limitation to the general consumer
            protection laws and regulations, or any other consumer protection
            laws that hinder unfair or misleading acts or practices;
          </p>
          <p>
            -without restraining the foregoing, you’ll make no calls to any
            person on any federal or state national Do-Not-Call (DNC) registry
            in the exception of an exemption;
          </p>
          <p>
            -you’ll use no lead information for the purpose of determining a
            person’s eligibility for employment, credit or otherwise in any
            manner that violates the Fair Credit Reporting Act;
          </p>
          <p>
            -you’ve disclosed the existence of any federal or state decrees,
            orders, or consent agreements, as well as any pending informal or
            formal government investigations. Also, you warrant that if you’re
            involved or named in any kind of action, complaint, investigation or
            any other proceeding by or before any governmental or regulatory
            agency, or any private party, you’ll provide immediate notice to
            Dependable Leads LLC of any such action, complaint, investigation or
            any other proceeding; and
          </p>
          <p>
            -you’ll make sure that your agents, employees, as well as
            contractors, comply with all the above.
          </p>
          <p>
            The leads services and the site are provided “as is”, “where is”.
            Neither party makes any sort of warranties to the other. Also, all
            statutory, implied or other warranties are disclaimed to the highest
            extent permitted by law, including with no limitation to any
            warranties of merchantability, fitness for a purpose,
            non-infringement, compliance with relevant laws, title, or while
            dealing or trading. We don’t guarantee in any way that any
            information provided by the consumer will be correct or complete, or
            that the results will be realized/guaranteed from the leads,
            including any customer conversion rates.
          </p>
          <p>
            You understand that leads and contact information gathered has not
            been screened or validated by us, and aren’t guaranteed to be
            accurate or error-free and all risks of condition, design, use,
            fitness, or quality are completely yours.
          </p>

          <p className="bold-big">Changes</p>
          <p>
            Dependable Leads LLC retains the right, based on sole discretion, to
            modify any part of this Agreement. It is totally your responsibility
            to check this Agreement periodically for changes. Your continued
            usage of the site following the posting of any changes to this
            Agreement suggests an acceptance of those changes. Also, Dependable
            Leads LLC Leads may, in the future, provide new services and/or
            features via the site (including, the release of new tools as well
            as resources). Any new features or/and services shall be subject to
            the Terms of this Agreement.
          </p>

          <p className="bold-big">Termination</p>
          <p>
            Dependable Leads LLC may terminate your access to all or any part of
            the site at any time, with or without cause, with or without notice.
            If you decide to terminate this Agreement or your account, you may
            simply discontinue using the site. Notwithstanding the foregoing, if
            you have an account, such account can only be terminated by
            Dependable Leads LLC if you materially breach this Agreement and
            fail to cure such breach within thirty (30) days of Dependable Leads
            LLC’s notice to you thereof; provided that, Dependable Leads LLC can
            cease the site at any time as part of a general shut down of our
            service.
          </p>
          <p className="bold-big">Disclaimer of Warranties</p>
          <p>
            The site is provided ‘as is’. Dependable Leads LLC and its
            suppliers, licensors and affiliate companies hereby disclaim all
            warranties of any kind, express or implied, including, without
            limitation, the warranties of merchantability, fitness for a
            particular purpose and non-infringement. Neither Calendar Leads nor
            its suppliers and licensors make any warranty that the site will be
            error-free or that access to it will be continuous or uninterrupted.
            You acknowledge that you download from, or otherwise acquire content
            or services via, the site at your own discretion and risk.
          </p>
          <p className="bold-big">Limitation of Liability</p>
          <p>
            In no event will Dependable Leads LLC, or its licensors or
            suppliers, be liable regarding any subject matter of this agreement
            under any contract, tort, negligence, liability or other legal or
            equitable theory for: (i) any incidental, special, or consequential
            damages; (ii) the cost of acquisition or substitute products or/and
            services; (iii) for the interruption of usage or loss or the
            corruption of data; or (iv) for any amounts that raise the fees paid
            by you to Dependable Leads LLC be under this agreement during the
            twelve (12) month period prior to the date the cause of the action
            accrues. Dependable Leads LLC shall have no form of liability for
            any failure or delay owing to matters beyond its reasonable control.
            The foregoing may not apply to the extent prohibited by relevant
            law.
          </p>
          <p className="bold-big">General Representation and Warranty</p>
          <p>
            Each user represents and warrants that (i) your use of the site will
            be in strict accordance with the Dependable Leads LLC Privacy
            Statement, with this Agreement as well as with all relevant laws and
            regulations (including any local laws or regulations in your
            country, state, city, or other governmental area, as regards online
            conduct and acceptable content, and including all relevant laws as
            regards the transmission of technical data exported from the U.S. or
            the country in which you dwell) and (ii) your usage of the site
            won’t infringe on the intellectual property rights of any third
            party.
          </p>
          <p className="bold-big">Indemnification </p>
          <p>
            You agree to indemnify and hold harmless Dependable Leads LLC, its
            contractors, and its licensors, as well as their respective
            directors, employees, agents and officers from and against any
            claims and expenses, including attorneys’ fees, resulting from your
            usage of the site, including from your violation this Agreement.
          </p>
          <p className="bold-big">Miscellaneous </p>
          <p>
            This Agreement constitutes the entire agreement between Dependable
            Leads LLC and you concerning the subject matter hereof, and they may
            only be modified by a written amendment signed by an authorized
            executive of Dependable Leads LLC, or by the posting by Dependable
            Leads LLC of a revised version.
          </p>
          <p>
            The dominant party in any action or proceeding to implement this
            Agreement shall be entitled to any costs as well as attorneys’ fees.
            If any area of this Agreement is deemed to be invalid or
            unenforceable, that area will be interpreted to reproduce the
            parties’ original intent, and the remaining areas will remain in
            full force and effect. Any waiver by any party of any term of this
            Agreement or any violation thereof, in any one instance, will not
            waive such term or any subsequent violation thereof. You may assign
            your rights within this Agreement to any party that consents to be
            bound by, its terms; Dependable Leads LLC may assign its rights
            within this Agreement without any condition. This Agreement will
            remain binding upon and will inure to the advantage of the parties,
            their successors as well as acceptable assigns.
          </p>
        </main>

        <style jsx>{`
          .container {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 1200px;
            position: relative;
            margin: 0 auto;
          }

          main {
            margin-top: 60px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          .grid {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            max-width: 800px;
            margin-top: 3rem;
          }
          .hero {
            background-color: blue;
          }
          .card-header {
            color: #000;
            margin-top: 75px;
          }
          .bold-big {
            font-size: 24px;
            font-weight: 800;
          }

          @media (max-width: 600px) {
            .grid {
              width: 100%;
              flex-direction: column;
            }
          }
        `}</style>

        <style jsx global>{`
          html,
          body {
            padding: 0;
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
          }
          @font-face {
            font-family: "Mulish";
            src: url("../public/fonts/Mulish-Black.ttf");
            font-weight: bold;
            font-style: normal;
          }

          * {
            box-sizing: border-box;
          }
        `}</style>
      </div>
      <Footer />
    </>
  );
};
