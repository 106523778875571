import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../theme";

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.footer.backgroundColor};
  padding: 96px 24px;
  width: 100%;
`;

export const StyledContent = styled.div`
  max-width: 1232px;
  display: flex;
  flex-direction: column;
  padding: 0 96px;
  width: 100%;
  
  @media (max-width: 960px) {
    padding: 0;
  }
`;

export const StyledLegalWrapper = styled.div`
  border-top: 1px solid ${theme.footer.dividingborderColor};
  padding-top: 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const StyledLinksWrapper = styled.div`
  display: flex;
  padding-bottom: 30px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const StyledSectionTitle = styled.span`
  font-size: 16px;
  line-height: 25px;
  color: ${theme.footer.sectionTitleColor};
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  margin-bottom: 12px;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  width: 275px;

  @media (max-width: 960px) {
    margin-bottom: 25px;
    width: unset;
  }
`;

export const StyledFooterLink = styled(Link)`
  font-size: 12px;
  line-height: 18px;
  color: ${theme.footer.sectionTitleColor};
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  text-decoration: none;
  margin-bottom: 12px;

  &:hover {
    color: ${theme.footer.sectionTitleColorHover};
  }

  &.disabled {
    opacity: 0.7;
    color: ${theme.footer.sectionTitleColor};
    cursor: not-allowed;
  }
`;

export const StyledLegalLinkWrapper = styled.div`
  display: flex;

  a {
    margin-left: 40px;
  }
`;

export const CopyrightText = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  color: ${theme.footer.copyrightColor};
  margin-bottom: 12px;
  padding: 0;
`;

export const LegalText = styled(Link)`
  font-size: 12px;
  line-height: 14px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  color: ${theme.footer.copyrightColor};
  margin-bottom: 12px;
  padding: 0;
  text-decoration: none;

  &:hover {
    color: ${theme.footer.legalLinkHover};
  }
`;
