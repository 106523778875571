import styled from "styled-components";
import { theme } from "../../theme";

export const H1 = styled.h1`
  font-size: 30px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  color: ${theme.primaryText.color};
  margin-top: 0;
`;

export const H2 = styled.h2`
  font-size: 28px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  color: ${theme.primaryText.color};
  margin-top: 0;
  letter-spacing: .5px;
  line-height: 34px;
`;

export const H3 = styled.h3`
  font-size: 22px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  letter-spacing: .5px;
  color: ${theme.primaryText.color};
  margin-top: 0;
`;

export const H4 = styled.h4`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  color: ${theme.primaryText.color};
  margin-bottom: 8px;
  margin-top: 0;
`;

export const H5 = styled.h5`
  font-size: 12px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  color: ${theme.primaryText.color};
  margin-bottom: 8px;
  margin-top: 0;
`;

export const P = styled.p`
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 27px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  color: ${theme.primaryText.color};
  margin-bottom: 8px;
  margin-top: 0;
`;
