import { useContext, useEffect } from "react";

import { useQuery } from "../../hooks/use-query";
import { useGenerateCampaign } from "../../data-hooks/use-generate-campaign";
import { PurchaseContext } from "../../context/purchase-context";
import { LocationDefs } from "../../constants/locations";
import { StyledSuccessContainer, ValidCheck, StyledCard } from "./styles";
import { Footer, H1, H4, H3, Nav, PageContainer } from "../../components";
import { theme } from "../../theme";
import { SessionStorageKeys } from "../../constants/session-storage";

const SUCCEEDED = "succeeded";

export const Success = () => {
  const query = useQuery();
  const { generateCampaign } = useGenerateCampaign();
  const { getSessionStorageValue, clearSession } = useContext(PurchaseContext);

  const paymentIntent = query.get("payment_intent");
  const paymentIntentClientSecret = query.get("payment_intent_client_secret");
  const redirectStatus = query.get("redirect_status");
  const leadLocationKey = getSessionStorageValue(
    SessionStorageKeys.LEAD_LOCATION_KEY
  );
  const locationDef = LocationDefs[leadLocationKey];
  const firstName = getSessionStorageValue(SessionStorageKeys.FIRST_NAME);
  const lastName = getSessionStorageValue(SessionStorageKeys.LAST_NAME);
  const email = getSessionStorageValue(SessionStorageKeys.EMAIL);
  const quantity = getSessionStorageValue(SessionStorageKeys.QUANTITY);
  const leadType = getSessionStorageValue(SessionStorageKeys.LEAD_TYPE);

  useEffect(() => {
    const handleGenerateCampaign = async () => {
      if (
        !paymentIntent &&
        !paymentIntentClientSecret &&
        redirectStatus !== SUCCEEDED &&
        Boolean(locationDef?.city && locationDef?.state) &&
        firstName &&
        lastName &&
        email &&
        quantity &&
        leadType
      ) {
        return;
      }
      await generateCampaign({
        paymentIntent,
        locationDef,
        firstName,
        lastName,
        email,
        quantity,
        leadType,
      });
      clearSession();
    };

    handleGenerateCampaign();
  }, [
    generateCampaign,
    paymentIntent,
    paymentIntentClientSecret,
    redirectStatus,
    leadLocationKey,
    locationDef,
    clearSession,
    email,
    firstName,
    lastName,
    quantity,
    leadType
  ]);

  return (
    <PageContainer>
      <Nav />
      <StyledSuccessContainer>
        <StyledCard>
          <ValidCheck color={theme.primaryButton.backgroundColor} />
          <H1>Sucess!</H1>
          <H3>Your Leads Are On The Way!</H3>
          <H4>Make sure to check your email for leads as they roll in!</H4>
        </StyledCard>
      </StyledSuccessContainer>
      <Footer />
    </PageContainer>
  );
};
