import styled from "styled-components";
import { Menu, Close } from "grommet-icons";
import { Link } from "react-router-dom";
import { theme } from "../../theme";

export const StyledButtonContainer = styled.div`
  display: flex;

  .nav-button {
    padding: 10px 32px;
    text-align: center;
    margin-top: 60px;
    width: 100%;
  }
`;

export const StyledLogo = styled.img`
  height: 26px;
  width: 55px;
`;

export const StyledNav = styled.nav`
  background: ${theme.mobileNav.backgroundColor};
  display: none;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  padding: 20px 40px;
  max-height: 81px;
  z-index: 100;

  @media (max-width: 960px) {
    padding: 20px 0;
    display: flex;
  }
`;

export const Takeover = styled.div`
  background: ${theme.mobileNav.backgroundColor};
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  min-height: 100vh;
  padding: 20px 0;

  &.open {
    display: block;
  }

  &.closed {
    display: none;
  }
`;

export const HeaderLogo = styled(Link)`
  width: 100px;
  cursor: pointer;
  display: flex;

  img {
    width: 55px;
  }

  &:hover {
    img {
      opacity: 95%;
    }
  }
`;

export const HeaderRow = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 0 20px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: ${theme.nav.linkColor};
  font-family: "Red Hat Display", sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-decoration: none;
  border-bottom: 3px solid transparent;

  &:hover,
  &.active {
    border-bottom-color: ${theme.nav.linkHoverBorderColor};
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;

    &:hover,
    &.active {
      border-bottom-color: transparent;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  align-items: center;
  justify-content: center;
  margin: 10px 20px 0 0;
  display: none;

  @media (max-width: 960px) {
    display: flex;
  }
`;

export const StyledClose = styled(Close)`
  &:hover {
    & path {
    }
  }
`;

export const CopyRight = styled.p`
  font-family: Poppins;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0 0 2px;
`;

export const CopyRightWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
`;

export const NavHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  svg {
    margin: 0;
  }
`;

export const StyledButtonLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;
