import { getStripeClientSecret } from "../api";

/**
 * Simple data hook to call our api to fetch the payment intent client secret.
 * @returns @type String: Stripe payment intent client secret
 */

export const useStripeClientSecret = () => {
  /**
   * Handler that makes a call our api to get stripe client secret from lambda and set purcahse intent.
   */
  const getClientSecret = async ({ type, quantity }) => {
    try {
      const secret = await getStripeClientSecret({ type, quantity: parseInt(quantity) });
      return secret;
    } catch(e) {
      console.log(e)
    }
  };

  /**
   * Return the client secret value out of the hook to be consumed by stripe.
   */
  return {
    getClientSecret,
  };
};

/**
 * Example useage:
 *   const { getClientSecret } = useStripeClientSecret();
 *   const handleGetClientSecret = async () => {
 *     const secret = await getClientSecret({ type: "gold", quantity: 30 });
 *     console.log(secret)
 *   };
 *
 * TODO: Drop and import into purchase page and plug in correct state values for type and quantitiy.
 */
