import { useState, useCallback } from "react";
import { generateCampaign as handleGenerateCampaign } from "../api";

/**
 * Simple data hook to call our api to generate a new campaign.
 */

export const useGenerateCampaign = () => {
  const [error, setError] = useState();
  const [data, setData] = useState();

  const generateCampaign = useCallback(
    async ({
      paymentIntent,
      locationDef,
      firstName,
      lastName,
      email,
      quantity,
      leadType,
    }) => {
      try {
        const reponse = await handleGenerateCampaign({
          input: {
            firstName,
            lastName,
            email,
            city: locationDef.city,
            state: locationDef.state,
            paymentIntent,
            leadQuantity: quantity,
            leadType,
          },
        });
        setData(reponse.data.generateCampaign);
      } catch ({ errors }) {
        setError(errors);
      }
    },
    [setError]
  );

  return {
    generateCampaign,
    error,
    data,
  };
};
