/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHelloWorld = /* GraphQL */ `
  query GetHelloWorld {
    getHelloWorld {
      message
    }
  }
`;
export const getStripeClientSecret = /* GraphQL */ `
  query GetStripeClientSecret($item: ItemInput!) {
    getStripeClientSecret(item: $item) {
      clientSecret
    }
  }
`;
