import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";

export const getStripeClientSecret = async (item) => {
  try {
    const results = await API.graphql(graphqlOperation(queries.getStripeClientSecret, { item }));
    return results.data.getStripeClientSecret.clientSecret;
  } catch (e) {
    console.log(e)
    throw new Error(e);
  }
};
