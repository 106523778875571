import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PurchaseContext } from "../../context/purchase-context";
import { useContext, useEffect, useCallback, useMemo } from "react";
import { Footer, Nav, PageContainer, CheckoutForm } from "../../components";
import { StripeAppearance } from "../../components/forms/checkout-form/styles";
import { LeadTypes } from "../../constants/types";
import { Paths } from "../../constants/paths";

import { StyledCheckoutContainer } from "./styles";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useStripeClientSecret } from "../../data-hooks/use-stripe-client-secret";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export const Checkout = () => {
  const { getClientSecret } = useStripeClientSecret();
  const { type } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    stripeClientSecret: clientSecret,
    setStripeClientSecret,
    quantity,
    leadLocationKey,
  } = useContext(PurchaseContext);

  const locationKeyParam = searchParams.get("location");
  const quantityParam = searchParams.get("quantity");

  const currentOrderURL = useMemo(() => {
    switch (type) {
      case LeadTypes.PLATINUM:
        return Paths.ORDER_PLATINUM;
      case LeadTypes.GOLD:
        return Paths.ORDER_GOLD;
      case LeadTypes.PRO:
      default:
        return Paths.ORDER_PRO;
    }
  }, [type]);

  const fetchClientSecret = useCallback(async () => {
    const reponse = await getClientSecret({
      type,
      quantity: parseInt(quantity),
    });
    setStripeClientSecret(reponse);
  }, [getClientSecret, setStripeClientSecret, type, quantity]);

  // Effects //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [type]);

  useEffect(() => {
    if (quantity !== quantityParam || locationKeyParam !== leadLocationKey) {
      navigate(currentOrderURL);
    }

    if (!clientSecret) {
      fetchClientSecret();
    }
  }, [
    quantity,
    clientSecret,
    currentOrderURL,
    fetchClientSecret,
    navigate,
    leadLocationKey,
    quantityParam,
    locationKeyParam,
  ]);

  return (
    <PageContainer>
      <Nav />
      <StyledCheckoutContainer id="checkout-contaner">
        {clientSecret && (
          <Elements
            options={{
              clientSecret,
              appearance: StripeAppearance,
            }}
            stripe={stripePromise}
          >
            <CheckoutForm leadType={type} />
          </Elements>
        )}
      </StyledCheckoutContainer>
      <Footer />
    </PageContainer>
  );
};
