import styled from "styled-components";
import backgroundImage from '../../assets/images/dl_background_rotated.jpeg';

export const StyledCheckoutContainer = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 80px;
  height: 100%;
  min-height: 100vh;
  width: 100%;
`;