export const Paths = {
  HOME: "/",
  ORDER: "/order/:type",
  ORDER_PRO: "/order/pro",
  ORDER_GOLD: "/order/gold",
  ORDER_PLATINUM: "/order/platinum",
  CHECKOUT: "/order/:type/checkout",
  CHECKOUT_PRO: "/order/pro/checkout",
  CHECKOUT_GOLD: "/order/gold/checkout",
  CHECKOUT_PLATINUM: "/order/platinum/checkout",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS: "/terms",
  SUCCESS: "/success",
  CONTACT: "/contact"
};
