import styled from "styled-components";
import { theme } from "../../theme";
import backgroundImage from "../../assets/images/dl_background.jpg";

export const StyledHero = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-top: 80px;
  background-image: url(${backgroundImage});
  background-size: cover;
  height: 800px;

  @media (max-width: 960px) {
    height: 100%;
  }

  .info-container {
    padding: 0px 70px 0 20px;
    width: 45%;

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  .image-container {
    padding: 0px 70px;
    width: 55%;

    @media (max-width: 960px) {
      padding: 35px 70px;
      width: 100%;
    }
  }
`;

export const GridContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 1328px;
  padding: 100px;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 12px;
  }
`;

export const GridHalf = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding: 70px;

  p {
    margin-bottom: 32px;
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const StyledContentImage = styled.img`
  height: 100%;
  width: 100%;
  max-height: 600px;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  &:before {
    content: "Unlock Your Agent Super Powers With Our High Intent Real Time Leads";
    display: block;
    padding: 12px 10px;
    border-radius: 8px;
    width: 200px;
    box-shadow: 10px 10px white;
    background-color: ${theme.hero.tagBackgroundColor};
    position: absolute;
    top: 100px;
    left: -50px;
    color: ${theme.hero.tagColor};
    font-family: "Red Hat Display", sans-serif;
    font-weight: 400;
    font-size: 12px;
  }
`;
