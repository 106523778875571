import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 96px 40px;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 1260px;
  padding: 0 40px;
  margin-bottom: 36px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 0;
  }

  h2 {
    margin-bottom: 10px;
  }

  h5 {
    opacity: 0.75;
  }
`;
