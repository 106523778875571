import { StyledInput } from "./styles";

export const Input = ({ className, step, min, type, placeholder, value, onChange }) => {
  return (
    <StyledInput
      className={className}
      placeholder={placeholder}
      min={min}
      type={type}
      step={step}
      value={value}
      onChange={onChange}
    />
  );
};
