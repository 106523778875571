import styled from "styled-components";
import backgroundImage from '../../assets/images/dl_background.jpg';
import { Validate } from "grommet-icons";
import { Card } from "../../components";

export const StyledSuccessContainer = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 80px;
  height: 100%;
  min-height: 100vh;
  width: 100%;
`;

export const ValidCheck = styled(Validate).attrs({
    size: "large",
  })`
    margin-right: 5px;
    margin-bottom: 5px;
  `;

  export const StyledCard = styled(Card)`
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin: 60px;
    padding: 40px;

    h1 {
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 5px;
    }
  `