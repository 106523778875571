import styled from "styled-components";

export const StyledOrderWrapper = styled.div`
  padding: 86px 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 720px;
  width: 100%;

  @media (max-width: 960px) {
    height: 100%;
    padding: 100px 0px 60px;
  }
`;
