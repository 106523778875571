import { LeadTypes } from "./types";

export const LeadDetailsDefs = {
  [LeadTypes.PRO]: {
    title: 'Custom Campaigns',
    price: 'Coming Soon',
    // items: [
    //   "",
    // ],
  },
  [LeadTypes.GOLD]: {
    title: 'Gold Leads',
    price: 33,
    items: [
      "7 Point Questionnaire",
      "Fast Delivery",
      "Targeted",
      "35 Mile Radius",
      "PLUS + Why Burial Coverage?",
    ],
  },
  [LeadTypes.PLATINUM]: {
    title: 'Platinum Leads',
    price: 50,
    items: [
      "7 Point Questionnaire",
      "Fast Delivery",
      "Targeted",
      "35 Mile Radius",
      "PLUS + Current Burial Coverage?",
      "PLUS + Why Burial Coverage?",
      "PLUS + Who is the Beneficiary?",
      "PLUS + How much coverage?",
      "PLUS + Tobacco use?", 
      "PLUS + Currently employed?",
    ],
  },
};
