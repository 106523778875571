import styled from "styled-components";
import { theme } from "../../../theme";
import { Card } from "../../card/card";
import { Input } from "../../input/input";
import { H5 } from "../../typography/typography";

export const StyledCard = styled(Card)`
  padding: 0;
  width: 960px;
  @media (max-width: 960px) {
    width: unset;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const StyledLabel = styled(H5)``;

export const StyledFeilds = styled.div`
  margin-top: 20px;
`;

export const StyledFeild = styled.div`
  margin-bottom: 20px;
`;

export const StyledForm = styled.form`
  width: 30vw;
  min-width: 500px;
  align-self: center;
  padding: 40px;
  margin-top: 10px;

  #payment-element {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 600px) {
    width: 80vw;
    min-width: initial;
  }
`;

export const StyledLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 45%;

  @media (max-width: 960px) {
    width: unset;
  }
`;

export const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: -2px 0 15px rgb(0 0 0 / 10%);
  padding: 40px;
  width: 55%;

  @media (max-width: 960px) {
    width: unset;
  }
`;

export const StyledPaymentButton = styled.button`
  background: #5469d4;
  font-family: "Red Hat Display", sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;

  &:hover {
    filter: contrast(115%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const StyledSpinner = styled.div`
  /* spinner/processing state, errors */
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &,
  &:before,
  &:after {
    border-radius: 50%;
  }

  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before,
  &:after {
    position: absolute;
    content: "";
  }

  &:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  &:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
`;

export const StyledPaymentMessage = styled.div`
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
`;

export const StyledFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const StripeAppearance = {
  variables: {
    fontFamily: "Red Hat Display, sans-serif",
    borderRadius: "10px",
    colorPrimaryText: theme.primaryText.color,
    spacingGridRow: "21px",
    colorBackground: "#ffffff",
  },
  rules: {
    ".Input": {
      border: `1px solid ${theme.input.borderColor}`,
      borderRadius: "6px",
      maxHeight: "38px",
      padding: "9px 16px",
    },
    ".Label": {
      fontWeight: "400",
      fontSize: "12px",
      marginBottom: "8px",
    },
  },
};
