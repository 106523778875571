import React, { useCallback, useEffect, useState } from "react";

import logo from "../../assets/images/New-DP-logo.svg";
import { theme } from "../../theme";
import { Paths } from "../../constants/paths";
import { useLocation } from "react-router-dom";
import logoSrc from "../../assets/images/New-DP-logo.svg";

import {
  StyledButtonContainer,
  StyledNav,
  Takeover,
  HeaderLogo,
  HeaderRow,
  Links,
  StyledLinkWrapper,
  StyledLink,
  StyledMenu,
  StyledClose,
  CopyRight,
  CopyRightWrapper,
  NavHeader,
  StyledButtonLink,
  StyledLogo,
} from "./mobile-styles";
import { Button } from "../button/button";

export const MobileNav = ({ activeTab }) => {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const handleMenuOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleMenuClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleLinkClick = useCallback(() => {
    handleMenuClose();
  }, [handleMenuClose]);

  const getActiveClassName = useCallback(
    (path) => {
      return path === pathname ? "active" : "";
    },
    [pathname]
  );

  useEffect(() => {
    return () => {
      handleMenuClose();
    };
  }, [handleMenuClose]);

  const TakeoverMenu = () => (
    <Takeover className={open ? "open" : "closed"}>
      <HeaderRow>
        <HeaderLogo to="/">
          <StyledLogo alt="Dependable Leads Logo" src={logoSrc} />
        </HeaderLogo>
        <StyledClose
          color={theme.mobileNav.linkColor}
          onClick={handleMenuClose}
        />
      </HeaderRow>
      <Links>
        <StyledLinkWrapper>
          <StyledLink
            className={getActiveClassName(Paths.HOME)}
            to={Paths.HOME}
            onClick={handleLinkClick}
          >
            Home
          </StyledLink>
        </StyledLinkWrapper>
        <StyledLinkWrapper>
          <StyledLink
            className={getActiveClassName(Paths.ORDER_GOLD)}
            to={Paths.ORDER_GOLD}
            onClick={handleLinkClick}
          >
            Order Gold
          </StyledLink>
        </StyledLinkWrapper>
        <StyledLinkWrapper>
          <StyledLink
            className={getActiveClassName(Paths.ORDER_PLATINUM)}
            to={Paths.ORDER_PLATINUM}
            onClick={handleLinkClick}
          >
            Order Platinum
          </StyledLink>
        </StyledLinkWrapper>
        <StyledLinkWrapper>
          <StyledLink
            className={'disabled'}
            // to={Paths.ORDER_PRO}
            // onClick={handleLinkClick}
          >
            Custom
          </StyledLink>
        </StyledLinkWrapper>
        <StyledButtonContainer>
          <StyledButtonLink to={Paths.CONTACT} onClick={handleLinkClick}>
            <Button
              type="button"
              className="nav-button"
              styleType="secondaryButton"
            >
              Get Help
            </Button>
          </StyledButtonLink>
        </StyledButtonContainer>
      </Links>
      <CopyRightWrapper>
        <CopyRight>© 2023 Dependable Leads</CopyRight>
      </CopyRightWrapper>
    </Takeover>
  );

  return (
    <StyledNav className="open">
      <NavHeader>
        <HeaderLogo to="/">
          <img alt="Dependable Leads Logo" src={logo} />
        </HeaderLogo>
        <StyledMenu
          color={theme.mobileNav.linkColor}
          onClick={handleMenuOpen}
        />
      </NavHeader>
      <TakeoverMenu />
    </StyledNav>
  );
};
