import { useEffect } from "react";
import { Nav, Footer } from "../../components";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Nav></Nav>
      <div className="container">
        <main>
          <h1 className="card-header">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You respond
            to our advertisements and tells You about Your privacy rights and
            how the law protects You. We use Your Personal Data to contact and
            support you, as well as to provide and improve the Service. By using
            the Service, You agree to the collection and use of information in
            accordance with this Privacy Policy.
          </h1>

          <p>
            <p className="bold-big">Interpretation and Definitions</p>
            <p className="bold-big">Interpretation</p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
            <p className="bold-big">Definitions</p>
            <p>For the purposes of this Privacy Policy:</p>
            <p>
              - Company (referred to as either "the Company", "We", "Us" or
              "Our" in this Agreement) refers to Dependable Leads.
            </p>
            <p>
              - Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
            </p>
            <p>
              - Personal Data is any information that relates to an identified
              or identifiable individual.
            </p>
            <p>
              - Service refers to the advertisement, website, or application.
            </p>
            <p>
              - Service Provider means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to
              perform services related to the Service or to assist the Company
              in analyzing how the Service is used.
            </p>
            <p>
              - Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </p>
            <p>
              - You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </p>
            <p className="bold-big">Collecting and Using Your Personal Data</p>
            <p className="bold-big">Types of Data Collected</p>
            Personal Data While using Our Service, We may ask You to provide Us
            with certain personally identifiable information that can be used to
            contact or identify You. Personally identifiable information may
            include, but is not limited to:
            <p>First name and last name</p>
            <p>Email address</p>
            <p>Phone number</p>
            <p>Location details such as your address, city, or country</p>
            <p>
              Any other data such as personal preferences, requirements, or
              comments
            </p>
            <p>Use of Your Personal Data</p>
            <p className="bold-big">
              The Company may use Personal Data for the following purposes:
            </p>
            <p>
              To provide and maintain our Service, including to monitor the
              usage of our Service.
            </p>
            <p>
              To manage Your Account: to manage Your registration as a user of
              the Service. The Personal Data You provide can give You access to
              different functionalities of the Service that are available to You
              as a registered user.
            </p>
            <p>
              For the performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or
              services You have purchased or of any other contract with Us
              through the Service.
            </p>
            <p>
              To contact You: To contact You by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a
              mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </p>
            <p>
              To provide You with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless You have opted not to receive such information.
            </p>
            <p>
              To manage Your requests: To attend and manage Your requests to Us.
            </p>
            <p>
              For business transfers: We may use Your information to evaluate or
              conduct a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of Our
              assets, whether as a going concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which Personal Data held by
              Us about our Service users is among the assets transferred.
            </p>
            <p>
              For other purposes: We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </p>
            <p className="bold-big">
              We may share Your personal information in the following
              situations:
            </p>
            <p>
              With Service Providers: We may share Your personal information
              with Service Providers to monitor and analyze the use of our
              Service, to contact You.
            </p>
            <p>
              For business transfers: We may share or transfer Your personal
              information in connection with, or during negotiations of, any
              merger, sale of Company assets, financing, or acquisition of all
              or a portion of Our business to another company.
            </p>
            <p>
              With Affiliates: We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </p>
            <p>
              With business partners: We may share Your information with Our
              business partners to offer You certain products, services or
              promotions.
            </p>
            <p>
              With other users: when You share personal information or otherwise
              interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed
              outside.
            </p>
            <p>
              With Your consent: We may disclose Your personal information for
              any other purpose with Your consent.
            </p>
            <p className="bold-big">Retention of Your Personal Data</p>
            <p>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              The Company will also retain Usage Data for internal analysis
              purposes.
            </p>
            <p className="bold-big">Transfer of Your Personal Data</p>
            <p>
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p>
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
            <p className="bold-big">Disclosure of Your Personal Data</p>
            <p>Law enforcement</p>
            <p>
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <p className="bold-big">Other legal requirements</p>
            <p>
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <p>Comply with a legal obligation</p>
            <p>Protect and defend the rights or property of the Company</p>
            <p>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </p>
            <p>
              Protect the personal safety of Users of the Service or the public
            </p>
            <p>Protect against legal liability</p>
            <p>Security of Your Personal Data</p>
            <p>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
            <p className="bold-big">Links to Other Websites</p>
            <p>
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <p className="bold-big">Changes to this Privacy Policy</p>
            <p>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <p>This Privacy Policy was last updated on 2022/10/31 21:37:32</p>
            <p className="bold-big">Contact Us</p>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us via email at help@dependableleads.co
            </p>
          </p>
        </main>

        <style jsx>{`
          .container {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 1200px;
            position: relative;
            margin: 0 auto;
          }

          main {
            margin-top: 60px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          .grid {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            max-width: 800px;
            margin-top: 3rem;
          }
          .hero {
            background-color: blue;
          }
          .card-header {
            color: #000;
            margin-top: 75px;
          }
          .bold-big {
            font-size: 24px;
            font-weight: 800;
          }

          @media (max-width: 600px) {
            .grid {
              width: 100%;
              flex-direction: column;
            }
          }
        `}</style>

        <style jsx global>{`
          html,
          body {
            padding: 0;
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
          }
          @font-face {
            font-family: "Mulish";
            src: url("../public/fonts/Mulish-Black.ttf");
            font-weight: bold;
            font-style: normal;
          }

          * {
            box-sizing: border-box;
          }
        `}</style>
      </div>
      <Footer />
    </>
  );
};
