import { useMemo } from "react";
import { StyledSelect } from "./styles";
import { createFilter } from "react-select";

export const Select = ({
  className,
  options,
  placeholder,
  onChange,
  value,
}) => {
  const memoSelect = useMemo(() => {
    return (
      <StyledSelect
        className={className}
        options={options}
        placeholder={placeholder}
        filterOption={createFilter({ ignoreAccents: false })}
        onChange={onChange}
        value={value}
      />
    );
  }, [className, options, placeholder, onChange, value]);

  return <>{memoSelect}</>;
};
