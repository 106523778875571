import { DownloadOption, Checkmark } from "grommet-icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { theme } from "../../theme";

export const StyledInfoCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1260px;
  padding: 0 40px;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    padding: 0;
  }
`;

export const StyledInfoCard = styled.div`
  display: flex;
  background: ${theme.card.backgroundColor};
  box-shadow: 3px 4px 15px rgb(0 0 0 / 10%);
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: calc(33.3333% - 20px); /* 1/3 minus the margins (20+20) */
  padding: 24px;

  &.coming-soon {
    opacity: 0.7;
  }

  @media (max-width: 960px) {
    width: 100%;
    padding: 24px;
    margin: 20px;
  }

  @media (max-width: 390px) {
    width: 100%;
    padding: 24px;
    margin: 20px;
  }

  h3 {
    color: ${theme.card.titleColor};
  }
`;

export const SyledCardFooter = styled.div``;

export const StyledCardLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${theme.card.titleColor};
  text-decoration: none;
  font-family: "Red Hat Display", sans-serif;

  &:hover {
    opacity: 0.75;
  }
`;

export const StyledArrow = styled(DownloadOption).attrs({
  size: "medium",
})`
  transform: rotate(270deg);
  margin-right: 8px;

  &&& path {
    fill: ${theme.card.titleColor};
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  height: 100%;
  width: 100%;
`;

export const StyledComingSoonContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  h3 {
    margin-bottom: 5px;
  }
`;

export const StyledPrice = styled.span`
  font-size: 40px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  color: ${theme.primaryText.color};
  display: flex;
  align-items: flex-start;

  span {
    font-size: 20px;
    opacity: 0.8;
    margin-left: 5px;
    margin-top: 7px;
  }
`;

export const StyledDetails = styled.ul`
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
`;

export const StyledDetail = styled.li`
  font-size: 12px;
  line-height: 18px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: ${theme.primaryText.color};
`;

export const StyledCheckmark = styled(Checkmark).attrs({
  size: "small",
})`
  margin-right: 5px;

  &&& path {
    stroke: ${theme.card.checkFillColor};
  }
`;

export const StyledDisclaimer = styled.span`
  font-size: 8px;
  line-height: 14px;
  font-family: "Red Hat Display",sans-serif;
  font-weight: 300;
  display: block;
`;

export const StyledDisclaimerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 35px;
`;