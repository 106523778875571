import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../theme";

export const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: ${theme.nav.backgroundColor};
  padding: 0 24px;
  position: fixed;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 10;

  &.hide {
    top: -80px;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

export const StyledLogo = styled.img`
  width: 66px;
`;

export const StyledLinkWrapper = styled.div`
  padding-left: 8px;
  padding-right: 2vw;
  @media (max-width: 960px) {
    text-align: center;
  }
`;

export const StyledLink = styled(Link)`
  color: ${theme.nav.linkColor};
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-decoration: none;
  position: relative;
  border-bottom: 2px solid transparent;
  transition: all 500ms ease;
  padding-bottom: 2px;

  &:hover,
  &.active {
    border-bottom-color: ${theme.nav.linkHoverBorderColor};
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;

    &:hover,
    &.active {
      border-bottom-color: transparent;
    }
  }
`;

export const StyledLinkContainer = styled.div`
  padding-left: 3vw;
  display: flex;
`;

export const StyledButtonContainer = styled.div`
  .nav-button {
    padding: 10px 32px;
  }
`;

export const StyledNavigatorsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLogoLink = styled(Link)`
  text-decoration: none;
`;

export const StyledButtonLink = styled(Link)`
  text-decoration: none;
`;
