import {
  StyledFooter,
  StyledContent,
  StyledLegalWrapper,
  StyledLinksWrapper,
  StyledSectionTitle,
  StyledColumn,
  StyledFooterLink,
  StyledLegalLinkWrapper,
  CopyrightText,
  LegalText,
} from "./styles";

import { Paths } from "../../constants/paths";

export const Footer = () => {
  return (
    <StyledFooter>
      <StyledContent>
        <StyledLinksWrapper>
          <StyledColumn>
            <StyledSectionTitle>Packages</StyledSectionTitle>
            {/* <StyledFooterLink to={Paths.ORDER_PRO}>Pro</StyledFooterLink> */}
            <StyledFooterLink to={Paths.ORDER_GOLD}>Gold</StyledFooterLink>
            <StyledFooterLink to={Paths.ORDER_PLATINUM}>
              Platinum
            </StyledFooterLink>
          </StyledColumn>
          <StyledColumn>
            <StyledSectionTitle>Info Center</StyledSectionTitle>
            {/* <StyledFooterLink>How it works?</StyledFooterLink>
            <StyledFooterLink>FAQ</StyledFooterLink> */}
            <StyledFooterLink className="disabled">Coming Soon</StyledFooterLink>
          </StyledColumn>
          <StyledColumn>
            <StyledSectionTitle>Contact Us</StyledSectionTitle>
            <StyledFooterLink to={Paths.CONTACT}>Help</StyledFooterLink>
          </StyledColumn>
        </StyledLinksWrapper>
        <StyledLegalWrapper>
          <CopyrightText>© 2023 Dependable Leads</CopyrightText>
          <StyledLegalLinkWrapper>
            <LegalText to={Paths.PRIVACY_POLICY}>Privacy</LegalText>
            <LegalText to={Paths.TERMS}>Terms</LegalText>
          </StyledLegalLinkWrapper>
        </StyledLegalWrapper>
      </StyledContent>
    </StyledFooter>
  );
};
