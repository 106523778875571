import styled from "styled-components";
import { theme } from "../../theme";

export const StyledCard = styled.div`
  display: flex;
  background: ${theme.card.backgroundColor};
  box-shadow: 3px 4px 15px rgb(0 0 0 / 10%);
  border-radius: 4px;
  padding: 24px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;