import { Nav, Footer, PageContainer, H1, H3, H5, H4 } from "../../components";
import { StyledContactContainer } from './styles';

export const Contact = () => {
  return (
    <PageContainer>
      <Nav />
      <StyledContactContainer>
        <H5>Need help or have questions?</H5>
        <H1>Contact Us</H1>
        <H3>Email</H3>
        <H4>help@dependableleads.co</H4>
      </StyledContactContainer>
      <Footer />
    </PageContainer>
  );
};
