import Select from "react-select";
import styled from "styled-components";

import { theme } from "../../theme";

export const StyledSelect = styled(Select)`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  outline: none;
  border-color: ${theme.select.borderColor};
  border-radius: 6px;
  color: ${theme.primaryText.color};
  max-width: 355px;
  width: 100%;

  &.error {
    border-color: ${theme.input.errorBorderColor};
  }

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;
