import {
  StyledInfoCard,
  SyledCardFooter,
  StyledComingSoonContentContainer,
  StyledDetails,
  StyledDetail,
  StyledCheckmark,
} from "./styles";
import { H3, H4 } from "../typography/typography";
import { useMemo } from "react";

export const InfoCardComingSoon = ({ className, leadDefinition, to }) => {
  const { title, items } = leadDefinition ?? {};

  const builtDetails = useMemo(() => {
    return items?.map((item) => (
      <StyledDetail key={item}>
        <StyledCheckmark />
        {item}
      </StyledDetail>
    ));
  }, [items]);

  return (
    <StyledInfoCard className={`${className} coming-soon`}>
      <StyledComingSoonContentContainer>
        <H3>{title}</H3>
        <H4>Coming Soon</H4>
        <StyledDetails>{builtDetails}</StyledDetails>
      </StyledComingSoonContentContainer>
      <SyledCardFooter></SyledCardFooter>
    </StyledInfoCard>
  );
};
