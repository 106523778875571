const colors = {
  blue1: "#295cd8",
  blue2: "#5784e0",
  blue3: "rgb(0, 100, 224)",
  white1: "#f5f5f6",
  white2: "#ffffff",
  slate1: "#384753",
  slate2: "#1c2b33",
  slate3: "#344854",
  slate4: "#465a69",
  darkGrey1: "#344854",
  darkGrey2: "#dddfe2",
  lightGrey1: "#cbd2d9",
  lightGrey2: "#F5F6F6",
  red1: "#FF3333",
};

export default colors;
