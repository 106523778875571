import colors from "../tokens/colors";

export const defaultTheme = {
  nav: {
    backgroundColor: colors.white1,
    linkColor: colors.darkGrey1,
    linkHoverBorderColor: colors.lightGrey1,
  },
  mobileNav: {
    backgroundColor: colors.white1,
    linkColor: colors.darkGrey1,
    linkHoverBorderColor: colors.lightGrey1,
  },
  primaryText: {
    color: colors.slate2,
  },
  primaryButton: {
    backgroundColor: colors.blue3,
    disabledBackgroundColor: colors.lightGrey1,
    color: colors.white1,
    hoverBackgroundColor: colors.blue2,
    hoverTextColor: colors.white2,
  },
  secondaryButton: {
    backgroundColor: colors.slate1,
    hoverBackgroundColor: colors.slate3,
    color: colors.white1,
    hoverTextColor: colors.white2,
  },
  footer: {
    backgroundColor: colors.slate2,
    dividingborderColor: colors.slate4,
    copyrightColor: colors.lightGrey1,
    sectionTitleColor: colors.lightGrey1,
    sectionTitleColorHover: colors.white2,
    legalLinkHover: colors.white2,
  },
  hero: {
    tagColor: colors.white1,
    tagBackgroundColor: colors.slate4,
  },
  card: {
    backgroundColor: colors.lightGrey2,
    titleColor: colors.slate3,
    checkFillColor: colors.blue3,
  },
  purchaseForm: {
    secondaryBackgroundColor: colors.lightGrey1,
    borderColor: colors.lightGrey1,
  },
  select: {
    borderColor: colors.darkGrey2,
  },
  input: {
    borderColor: colors.darkGrey2,
    errorBorderColor: colors.red1,
  },
};
