import {
  StyledHero,
  GridContainer,
  GridHalf,
  StyledContentImage,
  StyledImageContainer,
} from "./styles";
import { H1, H5, P } from "../typography/typography";
import { Button } from "../button/button";

import contentImageSrc from "../../assets/images/single-agent.jpg";

export const Hero = () => {
  return (
    <StyledHero>
      <GridContainer>
        <GridHalf className="info-container">
          <H5>Dependable Leads Is</H5>
          <H1>The Most Trusted Source Of High-Intent Final Expense Leads.</H1>
          <P>
            Annoyed with your funnel having disconnected numbers, duplicates, and never knowing if some other agent has already called your lead?
          </P>
          <P> Stop wasting time and valuable resources on low-quality
            leads from bulk distributors, get Dependable Leads' real time, unique leads and close more deals today.
          </P>
          <Button href="#packages">See packages</Button>
        </GridHalf>
        <GridHalf className="image-container">
          <StyledImageContainer>
            <StyledContentImage src={contentImageSrc} alt="Dependable Leads Super Agent Image" />
          </StyledImageContainer>
        </GridHalf>
      </GridContainer>
    </StyledHero>
  );
};
