import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Footer, Nav, PageContainer, OrderForm } from "../../components";
import { LeadDetailsDefs } from "../../constants/lead-details";
import { LeadTypes } from "../../constants/types";
import { PurchaseContext } from "../../context/purchase-context";
import { Paths } from "../../constants/paths";
import { LocationDefs } from "../../constants/locations";

import { StyledOrderWrapper } from "./styles";

export const Order = () => {
  // Hooks //
  const { type } = useParams();
  const navigate = useNavigate();
  const { setLeadLocationKey, quantity, leadLocationKey, setQuantity } =
    useContext(PurchaseContext);

  // State //
  const [locationEntires] = useState(Object.entries(LocationDefs));

  // Memos //
  const locationOptions = useMemo(() => {
    return locationEntires.map(([key, value]) => ({
      value: key,
      label: value.display,
    }));
  }, [locationEntires]);

  const currentDef = useMemo(() => {
    switch (type) {
      case LeadTypes.PLATINUM:
        return LeadDetailsDefs[LeadTypes.PLATINUM];
      case LeadTypes.GOLD:
        return LeadDetailsDefs[LeadTypes.GOLD];
      case LeadTypes.PRO:
        return LeadDetailsDefs[LeadTypes.PRO];
      default:
        return null;
    }
  }, [type]);

  const currentCheckoutURL = useMemo(() => {
    switch (type) {
      case LeadTypes.PLATINUM:
        return Paths.CHECKOUT_PLATINUM;
      case LeadTypes.GOLD:
        return Paths.CHECKOUT_GOLD;
      case LeadTypes.PRO:
        return Paths.CHECKOUT_PRO;
      default:
        return null;
    }
  }, [type]);

  // Hanlders //
  const handleNavigateCheckout = useCallback(async () => {
    navigate(
      `${currentCheckoutURL}?location=${leadLocationKey}&quantity=${quantity}`
    );
  }, [quantity, navigate, leadLocationKey, currentCheckoutURL]);

  const handleQuantityChange = useCallback(
    (quantity) => {
      setQuantity(quantity.toString());
    },
    [setQuantity]
  );

  const handleOnSubmit = useCallback(() => {
    handleNavigateCheckout();
  }, [handleNavigateCheckout]);

  const handleLocationOnChange = useCallback(
    ({ value: key }) => {
      setLeadLocationKey(key);
    },
    [setLeadLocationKey]
  );

  // Effects //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [type]);

  useEffect(() => {
    switch (type) {
      case LeadTypes.PLATINUM:
      case LeadTypes.GOLD:
        return;
      default:
        navigate(Paths.HOME);
    }
  }, [navigate, type]);

  return (
    <PageContainer>
      <Nav />
      <StyledOrderWrapper>
        <OrderForm
          quantity={quantity}
          leadDefinition={currentDef}
          selectOptions={locationOptions}
          selectValue={leadLocationKey}
          onSubmit={handleOnSubmit}
          onQuantityChange={handleQuantityChange}
          onLocationChange={handleLocationOnChange}
        />
      </StyledOrderWrapper>
      <Footer />
    </PageContainer>
  );
};
