import styled from "styled-components";

export const StyledContactContainer = styled.div`
  height: 600px;
  padding: 150px 86px 86px;
  background-color: white;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 100px;
  }
`;