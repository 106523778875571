import { InfoCards } from "../info-cards/info-cards";
import { InfoCard } from "../info-cards/info-card";
import { InfoCardComingSoon } from "../info-cards/info-card-coming-soon";
import { LeadTypes } from "../../constants/types";
import { LeadDetailsDefs } from "../../constants/lead-details";
import { H2, H5 } from "../typography/typography";

import { StyledContainer, StyledTitleWrapper } from "./styles";
import { Paths } from "../../constants/paths";

export const LeadPackages = ({ id }) => {
  return (
    <StyledContainer id={id}>
      <StyledTitleWrapper>
        <H2>Explore Our Comprehensive Lead Packages.</H2>
        <H5> Boost your sales and grow your business with our high-intent
            premium leads. Never bought in bulk, always unique and sent in real time.</H5>
      </StyledTitleWrapper>
      <InfoCards>
        <InfoCard
          leadDefinition={LeadDetailsDefs[LeadTypes.GOLD]}
          to={Paths.ORDER_GOLD}
        />
        <InfoCard
          leadDefinition={LeadDetailsDefs[LeadTypes.PLATINUM]}
          to={Paths.ORDER_PLATINUM}
        />
        <InfoCardComingSoon
          leadDefinition={LeadDetailsDefs[LeadTypes.PRO]}
          to={Paths.ORDER_PRO}
        />
      </InfoCards>
    </StyledContainer>
  );
};
