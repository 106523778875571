import styled from "styled-components";
import { theme } from "../../theme";
import { ButtonTypes } from "../../constants/types";

const getButtonTheme = (styleType) => {
  return theme[
    styleType === ButtonTypes.PRIMARY
      ? ButtonTypes.PRIMARY
      : ButtonTypes.SECONDARY
  ];
};

export const StyledButtonA = styled.a`
  background-color: ${({ styleType }) =>
    getButtonTheme(styleType).backgroundColor};
  color: ${({ styleType }) => getButtonTheme(styleType).color};
  font-family: "Red Hat Display", sans-serif;
  border-radius: 4px;
  font-size: 13px;
  outline: none;
  border: none;
  padding: 18px 32px;
  text-decoration: none;

  &:hover {
    background-color: ${({ styleType }) =>
      getButtonTheme(styleType).hoverBackgroundColor};
    color: ${({ styleType }) => getButtonTheme(styleType).hoverTextColor};
    cursor: pointer;
  }

  &.disabled {
    background-color: ${({ styleType }) => getButtonTheme(styleType).disabledBackgroundColor};
    cursor: not-allowed;
    &:hover {
      background-color: ${({ styleType }) => getButtonTheme(styleType).disabledBackgroundColor};
      color: ${({ styleType }) => getButtonTheme(styleType).color};
    }
  }
`;

export const StyledButton = styled.button`
  background-color: ${({ styleType }) =>
    getButtonTheme(styleType).backgroundColor};
  color: ${({ styleType }) => getButtonTheme(styleType).color};
  font-family: "Red Hat Display", sans-serif;
  border-radius: 4px;
  font-size: 13px;
  outline: none;
  border: none;
  padding: 18px 32px;
  text-decoration: none;

  &:hover {
    background-color: ${({ styleType }) =>
      getButtonTheme(styleType).hoverBackgroundColor};
    color: ${({ styleType }) => getButtonTheme(styleType).hoverTextColor};
    cursor: pointer;
  }

  &.disabled {
    background-color: ${({ styleType }) => getButtonTheme(styleType).disabledBackgroundColor};
    cursor: not-allowed;
    &:hover {
      background-color: ${({ styleType }) => getButtonTheme(styleType).disabledBackgroundColor};
      color: ${({ styleType }) => getButtonTheme(styleType).color};
    }
  }
`;
