export const LeadTypes = {
  PRO: "pro",
  GOLD: "gold",
  PLATINUM: "platinum",
};

export const ButtonTypes = {
  PRIMARY: "primaryButton",
  SECONDARY: "secondaryButton",
};
