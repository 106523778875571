import {
  StyledPrice,
  StyledDetails,
  StyledDetail,
  StyledCard,
  StyledLeft,
  StyledRight,
  StyledContentContainer,
  StyledCheckmark,
  StyledFooterWrapper,
  StyledUpperWrapper,
  StyledRow,
  StyledHeaderWrapper,
  StyledLeadQuanity,
  StyledTotalWrapper,
  StyledTotalLabel,
  StyledTotalValue,
  StyledDisclaimer,
  StyledSelectWrapper,
  StyledInputRow,
} from "./styles";
import { H2, H3, H5, Input, Select } from "../../";
import { useMemo } from "react";
import { Button } from "../../button/button";
import { useCallback } from "react";
import { StyledDisclaimerWrapper } from "../../info-cards/styles";

export const OrderForm = ({
  quantity,
  leadDefinition,
  selectOptions,
  selectValue,
  onSubmit,
  onQuantityChange,
  onLocationChange,
}) => {
  const { title, price, items } = leadDefinition ?? {};

  const handleQuantityOnChange = useCallback(
    (e) => {
      const newQuantity = e.target.value;
      onQuantityChange(newQuantity);
    },
    [onQuantityChange]
  );

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const totalCost = useMemo(() => {
    return quantity * price;
  }, [quantity, price]);

  const builtDetails = useMemo(() => {
    return items?.map((item) => (
      <StyledDetail key={item}>
        <StyledCheckmark />
        {item}
      </StyledDetail>
    ));
  }, [items]);

  const showMinValueError = useMemo(() => {
    if (parseInt(quantity) >= 20) return false;
    return true;
  }, [quantity]);

  const buttonDisabled = useMemo(() => {
    if (parseInt(quantity) >= 20) {
      return !(quantity && selectValue);
    }
    return true
  }, [quantity, selectValue]);

  return (
    <StyledCard>
      <StyledLeft>
        <H3>{title}</H3>
        <StyledContentContainer>
          <StyledPrice>${price}<span>*</span></StyledPrice>
          <StyledDetails>{builtDetails}</StyledDetails>
          <StyledDisclaimerWrapper>
            {/* <StyledDisclaimer>
              * PLUS questions are not required on form submission. You may not
              get this information.
            </StyledDisclaimer> */}
            <StyledDisclaimer>
              * This is Average cost per lead. Location, competition, and other
              out-of-our-control variables may lead to less or more leads at
              this price.
            </StyledDisclaimer>
          </StyledDisclaimerWrapper>
        </StyledContentContainer>
      </StyledLeft>
      <StyledRight>
        <StyledUpperWrapper>
          <StyledHeaderWrapper>
            <H2>
              Pick Your City, <br /> Watch Your Leads Roll In
            </H2>
            <H5>
              Lets get you selling! Simply pick the desired city for your leads
              and select the amount of leads you want to purchase.
            </H5>
          </StyledHeaderWrapper>
          <StyledRow>
            <StyledSelectWrapper>
              <H5>Select a City for your Leads</H5>
              <Select
                options={selectOptions}
                placeholder="Select City..."
                onChange={onLocationChange}
                value={selectOptions.filter(
                  (option) => option.value === selectValue
                )}
              />
            </StyledSelectWrapper>
            <StyledLeadQuanity>
              <H5>{`How many ${title} would you like?`}</H5>
              <StyledInputRow>
                <Input
                  type="number"
                  min="20"
                  step="5"
                  value={quantity}
                  onChange={handleQuantityOnChange}
                  className={showMinValueError && 'error'}
                />
                {showMinValueError && <span>Order Minimum is 20.</span>}
              </StyledInputRow>
            </StyledLeadQuanity>
            <StyledDisclaimer>
              * This is Average cost per lead. Location, competition, and other
              out-of-our-control variables may lead to less or more leads at
              this price.
            </StyledDisclaimer>
            <StyledTotalWrapper>
              <div>
                <StyledTotalLabel>Order Total:</StyledTotalLabel>
                <StyledTotalValue>${totalCost}</StyledTotalValue>
              </div>
            </StyledTotalWrapper>
          </StyledRow>
        </StyledUpperWrapper>
        <StyledFooterWrapper>
          <Button
            className={buttonDisabled && "disabled"}
            onClick={!buttonDisabled ? handleSubmit : () => {}}
          >
            Checkout
          </Button>
        </StyledFooterWrapper>
      </StyledRight>
    </StyledCard>
  );
};
