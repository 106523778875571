import {
  StyledInfoCard,
  SyledCardFooter,
  StyledCardLink,
  StyledArrow,
  StyledContentContainer,
  StyledPrice,
  StyledDetails,
  StyledDetail,
  StyledCheckmark,
  StyledDisclaimer,
  StyledDisclaimerWrapper,
} from "./styles";
import { H3 } from "../typography/typography";
import { useMemo } from "react";

export const InfoCard = ({ className, leadDefinition, to }) => {
  const { title, price, items } = leadDefinition ?? {};

  const builtDetails = useMemo(() => {
    return items?.map((item) => (
      <StyledDetail key={item}>
        <StyledCheckmark />
        {item}
      </StyledDetail>
    ));
  }, [items]);

  return (
    <StyledInfoCard className={className}>
      <H3>{title}</H3>
      <StyledContentContainer>
        <StyledPrice>${price}<span>*</span></StyledPrice>
        <StyledDetails>{builtDetails}</StyledDetails>
      </StyledContentContainer>
      <SyledCardFooter>
        <StyledCardLink to={to}>
          <StyledArrow />
          Learn More
        </StyledCardLink>
        <StyledDisclaimerWrapper>
          {/* <StyledDisclaimer>
            * PLUS questions are not required on form submission. You may not get
            this information.
          </StyledDisclaimer> */}
          <StyledDisclaimer>
            * This is Average cost per lead. Location, competition, and other out-of-our-control variables may
            affect how many leads are recieved.
          </StyledDisclaimer>
        </StyledDisclaimerWrapper>
      </SyledCardFooter>
    </StyledInfoCard>
  );
};
