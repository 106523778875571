/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://2qk2lz7nibfpngh7o6ef4rtozi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-z4xb4whndnftzaq6e7mrwqrzey",
    "aws_cloud_logic_custom": [
        {
            "name": "webhooks",
            "endpoint": "https://adtggbgfp0.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
