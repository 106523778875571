import styled from "styled-components";
import { theme } from "../../theme";

export const StyledInput = styled.input`
  outline: none;
  border: 1px solid ${theme.input.borderColor};
  border-radius: 6px;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  color: ${theme.primaryText.color};
  padding: 14px 16px;
  height: 38px;
  width: 75px;

  &.error {
    border-color: ${theme.input.errorBorderColor};
  }
`;
