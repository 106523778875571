import { useScrollDirection } from "../../hooks/use-scroll-direction";
import logo from "../../assets/images/New-DP-logo.svg";

import { Button } from "../button/button";
import { MobileNav } from "./mobile-nav";

import {
  StyledNav,
  StyledLogo,
  StyledLinkContainer,
  StyledButtonContainer,
  StyledNavigatorsWrapper,
  StyledLink,
  StyledLinkWrapper,
  StyledLogoLink,
  StyledButtonLink,
} from "./styles";
import { Paths } from "../../constants/paths";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";

export const Nav = () => {
  // Hooks //
  const scrollDirection = useScrollDirection();
  const { pathname } = useLocation();

  // Dynamic Classes //
  const scrollDirectionClass = scrollDirection === "down" ? "hide" : "show";

  const getActiveClassName = useCallback(
    (path) => {
      return path === pathname ? "active" : "";
    },
    [pathname]
  );

  return (
    <>
      <StyledNav className={scrollDirectionClass}>
        <StyledNavigatorsWrapper>
          <StyledLogoLink to={Paths.HOME}>
            <StyledLogo src={logo} alt="Dependable Leads Logo" />
          </StyledLogoLink>
          <StyledLinkContainer>
            <StyledLinkWrapper>
              <StyledLink
                className={getActiveClassName(Paths.HOME)}
                to={Paths.HOME}
              >
                Home
              </StyledLink>
            </StyledLinkWrapper>
            <StyledLinkWrapper>
              <StyledLink
                className={getActiveClassName(Paths.ORDER_GOLD)}
                to={Paths.ORDER_GOLD}
              >
                Order Gold
              </StyledLink>
            </StyledLinkWrapper>
            <StyledLinkWrapper>
              <StyledLink
                className={getActiveClassName(Paths.ORDER_PLATINUM)}
                to={Paths.ORDER_PLATINUM}
              >
                Order Platinum
              </StyledLink>
            </StyledLinkWrapper>
            <StyledLinkWrapper>
              <StyledLink
                className={'disabled'}
                // to={Paths.ORDER_PRO}
                disabled={true}
              >
                Custom
              </StyledLink>
            </StyledLinkWrapper>
          </StyledLinkContainer>
        </StyledNavigatorsWrapper>
        <StyledButtonContainer>
          <StyledButtonLink to={Paths.CONTACT}>
            <Button
              type="button"
              className="nav-button"
              styleType="secondaryButton"
            >
              Get Help
            </Button>
          </StyledButtonLink>
        </StyledButtonContainer>
      </StyledNav>
      <MobileNav />
    </>
  );
};
